import React, { useState, useEffect, useRef } from 'react'
import Theme from './Theme';
import { useDispatch, useSelector } from 'react-redux';
import { saveProfile } from '../../../redux/reducers/profileSlice';
import CropperCom from './CropperCom';
import inputErrors from '../../helpers/validation'
import Progress from './Progress';
import { useNavigate } from 'react-router-dom';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../helpers/Loader';
import { OverlayTrigger, Popover, PopoverHeader, PopoverBody, Tooltip } from 'react-bootstrap'
import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as EkOnkarIcon } from '../../../images/ekonkar.svg';
import { ReactComponent as GanpatiIcon } from '../../../images/ganpati2.svg';
import { ReactComponent as KhandaIcon } from '../../../images/khanda.svg';
import { ReactComponent as OmIcon } from '../../../images/om.svg';
import { ReactComponent as CrossIcon } from '../../../images/cross.svg';
import { ReactComponent as BismillahIcon } from '../../../images/bismillah.svg';
import { ReactComponent as NoneIcon } from '../../../images/none.svg';
import { ReactComponent as WaipIcon } from '../../../images/waip.svg';
import { ReactComponent as RingsIcon } from '../../../images/rings.svg';
import { ReactComponent as KnotIcon } from '../../../images/knot.svg';
const ExtraDetails = ({ onBack, onNext }) => {

    const formRef = useRef(null);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { weddingData: profileData, loading } = useSelector((state) => state.profile)
    const { user } = useSelector((state) => state.auth)
    const [showCountdown, setShowCountdown] = useState(false);
    const [linkDiv, setLinkDiv] = useState(false)
    const [errors, setErrors] = useState({})
    const [messageDiv, setMessageDiv] = useState(false)
    const [footerImageDiv, setFooterImageDiv] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false);
    const [showCropper, setShowCropper] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [values, setValues] = useState({
        countDownDate: "",
        preWeddingLink: "",
        specialMessage: "",
        instagramLink: "",
        facebookLink: "",
        theme: "theme1",
        footerImage: {
            url: "",
            public_id: ""
        },
        coverIcon: "",
        coverText: ""
    })
    const handleCheckboxChange = (e, type) => {
        if (type === "checkbox") {
            setShowCountdown(e.target.checked);
        } else if (type === "preWeddingLink") {
            setLinkDiv(e.target.checked)
        } else if (type === "specialMessage") {
            setMessageDiv(e.target.checked)
        } else if (type === "footerImage") {
            setFooterImageDiv(e.target.checked)
        }
    };
    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const rerenderTheme = (theme) => {
        setValues({
            ...values,
            theme: theme
        })
    }



    const handleSubmit = (e) => {

        e.preventDefault()
        console.log(values)
        const validationErrors = inputErrors(values)
        setErrors(validationErrors)
        setSubmitting(true)
    }


    useEffect(() => {
        if (isSubmitting) {
            const noErrors = Object.keys(errors).length === 0;
            if (noErrors) {
                savedata()
            } else {
                formRef.current.scrollIntoView({ behavior: "smooth" });
            }

        }
    }, [isSubmitting, errors])


    const savedata = () => {
        setSubmitting(false)
        const countDownDate = showCountdown ? values.countDownDate : "";
        const preWeddingLink = linkDiv ? values.preWeddingLink : "";
        const specialMessage = messageDiv ? values.specialMessage : "";
        const footerImage = footerImageDiv ? values.footerImage : {
            url: "",
            public_id: values.footerImage.public_id
        }


        const updatedValues = {
            ...values,
            countDownDate,
            preWeddingLink,
            specialMessage,
            footerImage,
        };
        dispatch(saveProfile({ values: updatedValues, onNext, lastStep: true, setIsDialogOpen }))
    }


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }
    const handleCropper = () => setShowCropper(prevValue => !prevValue)
    useEffect(() => {
        setValues({ ...profileData })
        setShowCountdown(!!profileData.countDownDate); // Convert truthy/falsy values to boolean
        setLinkDiv(!!profileData.preWeddingLink);
        setMessageDiv(!!profileData.specialMessage);
        setFooterImageDiv(!!profileData.footerImage.url)
    }, [profileData])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const iconMapping = {
        EkOnkarIcon: <EkOnkarIcon />,
        GanpatiIcon: <GanpatiIcon />,
        OmIcon: <OmIcon />,
        KhandaIcon: <KhandaIcon />,
        CrossIcon: <CrossIcon />,
        BismillahIcon: <BismillahIcon />,
        WaipIcon: <WaipIcon />,
        RingsIcon: <RingsIcon />,
        KnotIcon: <KnotIcon />,
        None: <NoneIcon />, // For "None" option
    };

    const handleViewDetails = () => {
        // navigate(`/${user&&user.userCode}`) 
        window.open(`/${user && user.userCode}`, '_blank');
        setIsDialogOpen(false);
    };

    const handleCreateEvent = () => {
        // Redirect to create events page
        navigate("/event/new")
        setIsDialogOpen(false);
    };

    const handleHome = () => {
        // Redirect to create events page
        navigate("/")
        setIsDialogOpen(false);
    };

    const handleIconSelect = (icon) => {
        setValues({
            ...values,
            coverIcon: icon
        })
    };

    if (loading) {
        return <Loader />
    }

    const displayedIcon = iconMapping[values.coverIcon] || null;

    return (
        <>
            <Progress activeStep={2} />
            <div className="form-screen">
                <div className="container mb-3">
                    <div className="row d-flex justify-content-center  flex-column align-items-center  mx-2">
                        <div className="col-lg-8 col-md-11 form-container">
                            <h3 className='form-heading'>Additional Details</h3>
                            <form className='form-div' onSubmit={handleSubmit} ref={formRef}>
                                <div className="condition-form-div">
                                    <label> Do you want a countdown? <input type="checkbox" checked={showCountdown} onChange={(e) => handleCheckboxChange(e, "checkbox")} /></label>
                                </div>

                                {showCountdown && (<><div className="mt-4 date-condition-div">
                                    <label><b>Choose Start or Wedding Day</b> <input type="date" name='countDownDate' value={values.countDownDate ? values.countDownDate : getCurrentDate()} onChange={handleChange} min={getCurrentDate()} /> </label>

                                </div>
                                    <small> </small>
                                </>
                                )}
                                <div className="condition-form-div">

                                    <label> Any Prewedding Link?
                                        <input type="checkbox" checked={linkDiv} onChange={(e) => handleCheckboxChange(e, "preWeddingLink")} /></label>
                                </div>
                                {linkDiv && (<div className={errors.preWeddingLink ? "form-input-div-error" : "form-input-div"}>
                                    <input type="text"
                                        name="preWeddingLink"
                                        value={values.preWeddingLink}
                                        onChange={handleChange}
                                        placeholder='Paste Your Link'
                                        className={errors.preWeddingLink ? "form-input-field-error" : "form-input-field"}
                                    />
                                    <small className='pre-small'> <b >[ Enter Prewedding or Song Link From <i style={{ color: "red" }} className="fab fa-youtube"></i> ]</b>

                                        <OverlayTrigger
                                            trigger="click"
                                            rootClose={true}
                                            placement="auto"
                                            overlay={
                                                <Popover id="popover-prewedding">
                                                    <PopoverHeader className='popover-header'>Pre-Wedding Video Feature 🎥</PopoverHeader>
                                                    <PopoverBody className='popover-body'>
                                                        Add a personal touch with your Pre-Wedding Video!<br />
                                                        Videos are hosted on YouTube for smooth playback.<br />
                                                        <a
                                                            href="https://wa.me/919321060218?text=Pre-Wedding Video Upload Help"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="popover-link"
                                                        >
                                                            Want to know more? Click here!
                                                        </a>
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                        >
                                            <InfoIcon className="tooltip-icon" />
                                        </OverlayTrigger>

                                    </small>
                                </div>)}

                                <div className='error-wedding-div'>  {errors.preWeddingLink && (<small className="error-msg-wedding">{errors.preWeddingLink}</small>)}</div>
                                <div className="condition-form-div">
                                    <label> Any Special Message? <input type="checkbox" checked={messageDiv} onChange={(e) => handleCheckboxChange(e, "specialMessage")} /></label>
                                </div>

                                {messageDiv && (<div className={errors.specialMessage ? "form-input-div-error" : "form-input-div"}>
                                    <input type="text"
                                        name="specialMessage"
                                        value={values.specialMessage}
                                        onChange={handleChange}
                                        placeholder='Enter Your Message'
                                        className={errors.specialMessage ? "form-input-field-error" : "form-input-field"}
                                    />
                                </div>)}
                                <div className='error-wedding-div'>  {errors.specialMessage && (<small className="error-msg-wedding">{errors.specialMessage}</small>)}</div>
                                <div className="condition-form-div">
                                    <label> Upload a Memorable Picture? <input type="checkbox" checked={footerImageDiv} onChange={(e) => handleCheckboxChange(e, "footerImage")} /></label>
                                </div>
                                {footerImageDiv && (<div className='file-input'>
                                    <label htmlFor="">Special Moment Snapshot</label>
                                    <div className='banner-buttons'>
                                        <button type="button" className='default-banner-button' onClick={handleCropper} >Upload or Change Image</button>
                                    </div>
                                </div>)}
                                <h3 className='form-heading-theme'>Select Background Theme</h3>
                                <div className="background-container-theme">
                                    <h2 className="theme-heading">Plain Backgrounds</h2>
                                    <Theme activeTheme={values.theme} theme="theme1" url="https://res.cloudinary.com/dtg40vnf7/image/upload/v1730209923/aamantran%20assets/pink15_q2zxlb.jpg" rerender={rerenderTheme} />
                                    <Theme activeTheme={values.theme} theme="theme2" url="https://res.cloudinary.com/depixc8dq/image/upload/v1726389611/aamantran%20assets/theme2_lwfsob.jpg" rerender={rerenderTheme} />
                                    <Theme activeTheme={values.theme} theme="theme3" url="https://res.cloudinary.com/dtg40vnf7/image/upload/v1730452947/aamantran%20assets/bluenew_wccysb.jpg" rerender={rerenderTheme} />
                                    <Theme activeTheme={values.theme} theme="theme6" url="https://res.cloudinary.com/depixc8dq/image/upload/v1718954800/aamantran%20assets/print7_dicfa0.jpg" rerender={rerenderTheme} />
                                    <h2 className="theme-heading">Printed Backgrounds</h2>
                                    <Theme activeTheme={values.theme} theme="theme4" url="https://res.cloudinary.com/depixc8dq/image/upload/v1718954818/aamantran%20assets/theme11_cwszsl.jpg" rerender={rerenderTheme} />
                                    <Theme activeTheme={values.theme} theme="theme5" url="https://res.cloudinary.com/depixc8dq/image/upload/v1718954817/aamantran%20assets/printbj3_ni2jux.jpg" rerender={rerenderTheme} />
                                    <Theme activeTheme={values.theme} theme="theme7" url="https://res.cloudinary.com/dtg40vnf7/image/upload/v1730452947/aamantran%20assets/pinkf4_tux8ia.jpg" rerender={rerenderTheme} />
                                </div>
                                <div className="religious-element-container">
                                    <h3 className="form-heading-theme">Choose Cover Page Icon
                                        <OverlayTrigger placement="auto" overlay={
                                            <Tooltip id="tooltip-group">
                                                <span>
                                                    Choose a religious symbol or use the default icons or none for no icon. Add a short text accordingly!
                                                </span>
                                            </Tooltip>
                                        }>
                                            <InfoIcon className='tooltip-icon' />
                                        </OverlayTrigger>
                                    </h3>
                                    <div className="icon-selection d-flex align-items-center">
                                        <div className="icon-display">{displayedIcon}</div>
                                        <EditIcon className='edit-reli-icon' data-bs-toggle="modal" data-bs-target="#iconModal" />
                                    </div>
                                </div>

                                <div className="religious-text-field mt-3">
                                    <textarea
                                        name="coverText"
                                        value={values.coverText}
                                        onChange={handleChange}
                                        placeholder="Enter short text"
                                        rows="2"
                                        className="form-control"
                                    />
                                </div>
                                <div className='error-wedding-div p-2 error-center' >  {errors.coverText && (<small className="error-msg-wedding">{errors.coverText}</small>)}</div>
                                <div
                                    className="modal fade"
                                    id="iconModal"
                                    tabIndex="-1"
                                    aria-labelledby="iconModalLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog modal-dialog-scrollable">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="iconModalLabel">Select Religious Icon</h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                ></button>
                                            </div>

                                            <div className="modal-body">
                                                <div className="icon-options d-flex flex-wrap gap-3">
                                                    {/* Option for no icon */}
                                                    <div className="icon-option" onClick={() => handleIconSelect("None")}>
                                                        <NoneIcon />
                                                    </div>
                                                    <div className="icon-option" onClick={() => handleIconSelect("RingsIcon")}>
                                                        <RingsIcon />
                                                    </div>
                                                    <div className="icon-option" onClick={() => handleIconSelect("KnotIcon")}>
                                                        <KnotIcon />
                                                    </div>
                                                    <div className="icon-option" onClick={() => handleIconSelect("GanpatiIcon")}>
                                                        <GanpatiIcon />
                                                    </div>
                                                    <div className="icon-option" onClick={() => handleIconSelect("OmIcon")}>
                                                        <OmIcon />
                                                    </div>
                                                    <div className="icon-option" onClick={() => handleIconSelect("EkOnkarIcon")}>
                                                        <EkOnkarIcon />
                                                    </div>
                                                    <div className="icon-option" onClick={() => handleIconSelect("KhandaIcon")}>
                                                        <KhandaIcon />
                                                    </div>
                                                    <div className="icon-option" onClick={() => handleIconSelect("CrossIcon")}>
                                                        <CrossIcon />
                                                    </div>
                                                    <div className="icon-option" onClick={() => handleIconSelect("BismillahIcon")}>
                                                        <BismillahIcon />
                                                    </div>
                                                    <div className="icon-option" onClick={() => handleIconSelect("WaipIcon")}>
                                                        <WaipIcon />
                                                    </div>
                                                    {/* Add more icons here similarly */}
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                                    Select
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <h3 className='form-heading-theme'>Add Social Media</h3>
                                <div className={errors.instagramLink ? "form-input-div-error mt-4" : "form-input-div mt-4"}>
                                    <input type="text"
                                        name="instagramLink"
                                        value={values.instagramLink}
                                        onChange={handleChange}
                                        placeholder=' '
                                        className={errors.instagramLink ? "form-input-field-error" : "form-input-field"}
                                    />
                                    <label>Instagram Link</label>
                                </div>

                                <div className='error-wedding-div'>  {errors.instagramLink && (<small className="error-msg-wedding">{errors.instagramLink}</small>)}</div>

                                <div className={errors.facebookLink ? "form-input-div-error mt-4" : "form-input-div mt-4"}>
                                    <input type="text"
                                        name="facebookLink"
                                        value={values.facebookLink}
                                        onChange={handleChange}
                                        placeholder=' '
                                        className={errors.facebookLink ? "form-input-field-error" : "form-input-field"}
                                    />
                                    <label>Facebook Link</label>
                                </div>
                                <div className='error-wedding-div'>  {errors.facebookLink && (<small className="error-msg-wedding">{errors.facebookLink}</small>)}</div>
                                <div className='form-double-buttons'>
                                    <button type='button' onClick={onBack} >Back</button>
                                    <button type='submit'>Submit</button>
                                </div>
                            </form>
                            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                                <DialogTitle>Success</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Details saved successfully! What would you like to do next?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>

                                    <Button onClick={handleViewDetails}>View Details</Button>
                                    <Button onClick={handleCreateEvent}>Create Events</Button>
                                    <Button onClick={handleHome}>Get Back To Home Page</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>

            {showCropper && <CropperCom handleCropper={handleCropper} setValues={setValues} values={values} imageType="footerImage" />}
        </>
    )
}

export default ExtraDetails