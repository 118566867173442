
const inputErrors = values => {
    let errors = {};
    const names = Object.keys(values);

    names.forEach(name => {

        switch (name) {
            case "email":
                if (!values.email) {
                    errors.email = "Please enter email"
                } else if (! /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/i.test(values.email)) {
                    errors.email = "Please enter valid email"
                }

                break;
            case "password":
                if (!values.password) {
                    errors.password = "Please enter password"
                }
                break;
            case "confirmPassword":
                if (!values.confirmPassword) {
                    errors.confirmPassword = "Please enter confirm password"
                }
                break;

            case "oldPassword":
                if (!values.oldPassword) {
                    errors.oldPassword = "Please enter old password"
                }
                break;


            case "signUpCode":
                if (!values.signUpCode) {
                    errors.signUpCode = "Please enter sign up code"
                }
                break;
            case "eventName":
                if (!values.eventName) {
                    errors.eventName = "Event name is required"
                }
                if (values.eventName.length > 30) {
                    errors.eventName = "Max length reached [Allowed Characters 30]"
                }
                break;

            case "eventTime":
                if (!values.eventTime) {
                    errors.eventTime = "Event Time is required"
                }else if(values.eventTime==="Invalid Date"){
                    errors.eventTime = "Enter valid time"
                }
                break;


            case "eventTheme":
                if (values.eventTheme.length > 80) {
                    errors.eventTheme = "Max length reached [Allowed Characters 80]"
                }
                break;

            case "eventAddress":
                if (values.eventAddress.length > 100) {
                    errors.eventAddress = "Max length reached [Allowed Characters 100]"
                }
                break;

            case "eventInfo":
                if (values.eventInfo.length > 200) {
                    errors.eventInfo = "Max length reached [Allowed Characters 200]"
                }
                break;
            case "groomName":
                if (!values.groomName) {
                    errors.groomName = "Groom Name is required"
                } else if (values.groomName.length > 17) {
                    errors.groomName = "Max length reached [Allowed Characters 17]"
                }
                break;

            case "brideName":
                if (!values.brideName) {
                    errors.brideName = "Bride Name is required"
                } else if (values.brideName.length > 17) {
                    errors.brideName = "Max length reached [Allowed Characters 17]"
                }
                break;
            case "groomFullName":
                if (!values.groomFullName) {
                    errors.groomFullName = "Groom Full Name is Required"
                } else if (values.groomFullName.length > 50) {
                    errors.groomFullName = "Max length reached [Allowed Characters 50]"
                }
                break;

            case "brideFullName":
                if (!values.brideFullName) {
                    errors.brideFullName = "Bride Full Name is Requird"
                } else if (values.brideFullName.length > 50) {
                    errors.brideFullName = "Max length reached [Allowed Characters 50]"
                }
                break;

            case "hashtag":
                if (values.hashtag.length > 20) {
                    errors.hashtag = "Max length reached [Allowed Characters 20]"
                }
                break;
            case "groomParentDetail":
                if (values.groomParentDetail.length > 70) {
                    errors.groomParentDetail = "Max length reached [Allowed Characters 70]"
                }
                break;

            case "brideParentDetail":
                if (values.brideParentDetail.length > 70) {
                    errors.brideParentDetail = "Max length reached [Allowed Characters 70]"
                }
                break;
            case "preWeddingLink":
                if (values.preWeddingLink && !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(values.preWeddingLink)) {
                    errors.preWeddingLink = "Please enter a valid URL";
                }
                break;
            case "facebookLink":
                if (values.facebookLink && !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(values.facebookLink)) {
                    errors.facebookLink = "Please enter a valid URL";
                }
                break;
            case "instagramLink":
                if (values.instagramLink && !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(values.instagramLink)) {
                    errors.instagramLink = "Please enter a valid URL";
                }
                break;
            case "specialMessage":
                if (values.specialMessage && values.specialMessage.length > 125) {
                    errors.specialMessage = "Max length reached [Allowed Characters 125]";
                }
                break;
            case "linkTitle":
                if (!values.linkTitle) {
                    errors.linkTitle = "title is required"
                } else if (values.linkTitle.length > 44) {
                    errors.linkTitle = "Max length reached [Allowed Characters 44]"
                }
                break;
            case "url":
                if (!values.url) {
                    errors.url = "url is required"
                } else if (!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(values.url)) {
                    errors.url = "Invalid URL.";
                }
                break;
            case "brideGroomName":
                if (!values.brideGroomName) {
                    errors.brideGroomName = "name is required"
                }
                if (values.brideGroomName.length > 50) {
                    errors.brideGroomName = "Max length reached [Allowed Characters 50]"
                }
                break;
            case "message":
                if (values.message && values.message.length > 250) {
                    errors.message = "Max length reached [Allowed Characters 250]";
                }
                break;
                case "coverText":
                    if (values.coverText && values.coverText.length > 64) {
                        errors.coverText = "Max length reached [Allowed Characters 64 (32 each line)]";
                    }
                    break;    
            default:
                break;

        }
    })
    return errors;
}

export default inputErrors;